import { keys, forEach } from 'lodash-es'
import { tryExceptAwait } from '@/utils'
import filterEndpoints from '@/api/filters'

// initial state
const getDefaultState = () => {
  return {
    filters: {},
    pickedFilters: {},
    searchInput: '',
    page: 1
  }
}

// getters
const getters = {
  getFilters(state) {
    return state.filters
  },
  getPickedFilters(state) {
    return state.pickedFilters
  },
  getSearchInput(state) {
    return state.searchInput
  },
  getPage(state) {
    return state.page
  },
  getQueryParams(state) {
    const filters = {}
    forEach(state.pickedFilters, (filter, key) => {
      filters[key] = filter.join()
    })
    return {
      ...filters,
      q: state.searchInput
    }
  }
}

// mutations
const mutations = {
  setFilters(state, filters) {
    state.filters = filters
  },
  setPickedFilters(state, pickedFilters) {
    state.pickedFilters = pickedFilters
  },
  setPickedFilterGroups(state, groups) {
    forEach(groups, (group) => (state.pickedFilters[group] = []))
  },
  setSearchInput(state, value) {
    state.searchInput = value
  },
  setPage(state, page) {
    state.page = page
  }
}

// actions
const actions = {
  async loadFilters({ state, commit }) {
    const response = await tryExceptAwait(filterEndpoints.getFabricFilters, [])
    if (response.data) {
      commit('setFilters', response.data)
      commit('setPickedFilterGroups', keys(state.filters))
    }
  },
  async load({ dispatch }) {
    await dispatch('loadFilters')
  },
  async updatePickedFilters({ commit }, filters) {
    commit('setPickedFilters', filters)
  },
  async updateSearchInput({ commit }, value) {
    commit('setSearchInput', value)
  },
  setPage({ commit }, value) {
    commit('setPage', value)
  }
}

export default {
  namespaced: true,
  state: getDefaultState,
  getters,
  mutations,
  actions
}
