import apiClient from './index'
import { RAIL_ROD_PARTS } from '@/constants'

export default {
  async getRailRodParts(queryParams) {
    return await apiClient.get(`api/accessories/${RAIL_ROD_PARTS}/`, {
      params: queryParams
    })
  }
}
