<template>
  <overlay :show-overlay="showOverlay" :show-close-button="false">
    <template>
      <v-list v-click-outside="clickOutside" class="account-overlay" light>
        <v-subheader @click="emit('closeAccountOverlay')">
          <v-list-item-avatar>
            <v-avatar color="primary" size="30">
              <span class="initial">
                {{ debtorInitial }}
              </span>
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-title>
            {{ debtorInfo }}
          </v-list-item-title>
          <v-list-item-avatar>
            <v-icon> mdi-chevron-up </v-icon>
          </v-list-item-avatar>
        </v-subheader>

        <!-- logout button -->
        <v-list-item data-test="accountLogout" @click="logout">
          <v-list-item-icon>
            <v-icon> mdi-exit-to-app </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ t('components.accountOverlay.buttons.logout') }}
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-avatar size="20">
            <v-icon color="black"> mdi-chevron-right </v-icon>
          </v-list-item-avatar>
        </v-list-item>

        <!-- account details -->
        <div v-if="debtorDetails.length" class="accountDetails pt-3 pr-4 pl-4">
          <div v-for="(debtorDetail, index) in debtorDetails" :key="index" class="mb-4">
            <div class="accountDetails__label">{{ debtorDetail.label }}</div>
            <div class="accountDetails__info">{{ debtorDetail.value }}</div>
          </div>
        </div>
      </v-list>
    </template>
  </overlay>
</template>

<script setup>
import { computed, toRefs } from 'vue'
import { useI18n } from 'vue-i18n-composable'
import { useStore, useNamespacedGetters } from 'vuex-composition-helpers'
//components
import Overlay from './Overlay'

//data
const props = defineProps({
  showOverlay: {
    type: Boolean,
    default: false
  }
})
const { showOverlay } = toRefs(props)
const store = useStore()

const { getDebtor } = useNamespacedGetters('user', ['getDebtor'])

//state
const { t } = useI18n()

//computed
const debtorInfo = computed(() => {
  return Object.keys(getDebtor.value).length
    ? `${getDebtor.value.name} ${getDebtor.value.city}`
    : 'Unknown debtor'
})
const debtorInitial = computed(() => {
  return Object.keys(getDebtor.value).length && getDebtor.value.name
    ? `${getDebtor.value.name[0]}`
    : 'U'
})
const debtorDetails = computed(() => {
  const debtorDetailsInfo = []
  if (Object.keys(getDebtor.value).length) {
    if (getDebtor.value.name) {
      debtorDetailsInfo.push({
        label: t('components.accountOverlay.clientInfo.name'),
        value: getDebtor.value.name
      })
    }
    if (getDebtor.value.address) {
      debtorDetailsInfo.push({
        label: t('components.accountOverlay.clientInfo.address'),
        value: getDebtor.value.address
      })
    }
    if (getDebtor.value.postalcode) {
      debtorDetailsInfo.push({
        label: t('components.accountOverlay.clientInfo.postcode'),
        value: getDebtor.value.postalcode
      })
    }
    if (getDebtor.value.city) {
      debtorDetailsInfo.push({
        label: t('components.accountOverlay.clientInfo.city'),
        value: getDebtor.value.city
      })
    }
    if (getDebtor.value.country) {
      debtorDetailsInfo.push({
        label: t('components.accountOverlay.clientInfo.country'),
        value: getDebtor.value.country
      })
    }
    if (getDebtor.value.phone) {
      debtorDetailsInfo.push({
        label: t('components.accountOverlay.clientInfo.phoneNumber'),
        value: getDebtor.value.telephone
      })
    }
    if (getDebtor.value.email) {
      debtorDetailsInfo.push({
        label: t('components.accountOverlay.clientInfo.email'),
        value: getDebtor.value.email
      })
    }
    if (getDebtor.value.delivery_trips) {
      const deliveryDays = getDebtor.value.delivery_trips.map((delivery_trip) =>
        t(`enums.DeliveryDay.${delivery_trip.delivery_day}`)
      )
      debtorDetailsInfo.push({
        label: t('components.accountOverlay.clientInfo.deliveryDay'),
        value: deliveryDays.join(', ')
      })
    }
  }
  return debtorDetailsInfo
})

//methods
const clickOutside = () => {
  if (showOverlay.value) {
    emit('closeAccountOverlay')
  }
}
const logout = async () => {
  await store.dispatch('user/logout')
}

const emit = defineEmits(['closeAccountOverlay'])
</script>

<style lang="scss" scoped>
:deep .v-overlay__content {
  position: fixed;
  top: 5px;
  right: 12px;
}

.v-sheet.v-list {
  min-width: 300px;
  border: 1px #d3d3d4 solid;
  border-radius: 0.7em;
}

.v-list {
  .v-subheader {
    margin-bottom: 3px;
    cursor: pointer;

    .v-list-item__title {
      margin-left: 10px;
    }

    .v-list-item__icon {
      color: gray;
    }

    .v-list-item__avatar {
      justify-content: center;
      margin-right: 0;

      .v-icon {
        color: gray;
      }

      .initial {
        margin-top: 2px;
        font-size: 12px;
        font-weight: 500;
        color: white;
      }
    }
  }

  .v-icon {
    padding-left: 11px;
    font-size: 18px;
    color: #b8860a;
  }

  .v-list-item {
    min-height: 25px;
    font-weight: 600;

    &--active {
      border-color: $primary-color;

      .v-list-item__title {
        font-weight: bold !important;
      }
    }

    .v-list-item__icon {
      margin: 10px 20px 5px 0;
    }

    .v-avatar.v-list-item__avatar {
      margin-right: 10px;
    }

    path {
      fill: $primary-color;
    }
  }

  .accountDetails {
    border-top: 1px solid $grey-color;
    font-size: 14px;
    &__label {
      color: $account-info-color;
    }

    &__info {
      max-width: 330px;
      font-weight: bold;
    }
  }
}
</style>
