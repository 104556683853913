import { RAIL_ROD_PARTS, CREATED, OK, BAD_REQUEST, FORBIDDEN } from '@/constants'
import { createArticleOrder, updateArticleOrder } from '@/utils'

const getDefaultState = () => {
  return {
    // id of the rail rod parts article order being edited
    railRodPartsOrderNumber: undefined,
    // parameters which can be used to create an order
    railRodPartsParameters: {},
    // the price object returned by the rail rod parts price endpoint
    railRodPartsPriceData: {}
  }
}

// initial state
const state = getDefaultState()

// getters
const getters = {
  getRailRodPartsParameters(state) {
    return state.railRodPartsParameters
  },
  getRailRodPartsOrderNumber(state) {
    return state.railRodPartsOrderNumber
  },
  getRailRodPartsPriceData(state) {
    return state.railRodPartsPriceData
  }
}

// mutations
const mutations = {
  setRailRodPartsParameters(state, railRodPartsParameters) {
    state.railRodPartsParameters = railRodPartsParameters
  },
  setRailRodPartsOrderNumber(state, orderNumber) {
    state.railRodPartsOrderNumber = orderNumber
  },
  setRailRodPartsPriceData(state, railRodPartsPriceData) {
    state.railRodPartsPriceData = railRodPartsPriceData
  },
  resetState() {
    Object.assign(state, getDefaultState())
  }
}

// actions
const actions = {
  setRailRodPartsParameters({ commit }, railRodPartsParameters) {
    commit('setRailRodPartsParameters', railRodPartsParameters)
  },
  setRailRodPartsPriceData({ commit }, railRodPartsPriceData) {
    commit('setRailRodPartsPriceData', railRodPartsPriceData)
  },
  resetState({ commit }) {
    commit('resetState')
  },
  async createOrUpdateRailRodPartsOrder({ state, commit, dispatch, rootGetters }) {
    const orderId =
      rootGetters['order/getOrderId'] ||
      (await dispatch('order/getOrCreateDraftOrder', null, { root: true }))
    const { data, status } = state.railRodPartsOrderNumber
      ? // update existing rail rod parts order
        await updateArticleOrder(
          orderId,
          state.railRodPartsOrderNumber,
          RAIL_ROD_PARTS,
          state.railRodPartsParameters
        )
      : // create new rail rod parts order
        await createArticleOrder(orderId, RAIL_ROD_PARTS, state.railRodPartsParameters)

    if (status === OK || status === CREATED) {
      commit('order/setErrorMessage', {}, { root: true })
      commit('setRailRodPartsOrderNumber', data.order_number)
    } else if (status >= BAD_REQUEST && status !== FORBIDDEN) {
      commit('order/setErrorMessage', data, { root: true })
      console.error(
        'There was an error while creating or updating a rail rod parts order'
      )
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
