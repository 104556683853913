import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router/router'
import vuetify from './plugins/vuetify'
import VueHead from 'vue-head'
import './plugins/sentry'
import vueWait from './plugins/vueWait'
import { FlowGeneratorBus } from 'vue-flow-generator/src/plugins/bus'
import VueGtag from 'vue-gtag'
import i18n from './i18n'
import emitter from '@/services/emitter'
import { configure } from 'vee-validate'

Vue.use(VueHead)

if (process.env.LEVEL === 'production') {
  Vue.use(VueGtag, {
    config: { id: process.env.VUE_APP_GOOGLE_ANALYTICS_TRACKING_ID }
  })
}

Vue.config.productionTip = false
Vue.config.ignoredElements = [
  'custom-widget',
  'lining_fabric',
  'rail_curve_presentation',
  'with_child_safety'
]
Vue.prototype.$flowGeneratorBus = FlowGeneratorBus

const vue = new Vue({
  provide: {
    wait: vueWait,
    emitter
  },
  render: (h) => h(App),
  router,
  store,
  wait: vueWait,
  vuetify,
  i18n
}).$mount('#app')

if (window.Cypress) {
  window.vue = vue
}

// try to unregister any dangling service workers
try {
  navigator.serviceWorker.getRegistrations().then(function (registrations) {
    for (let registration of registrations) {
      registration.unregister()
    }
  })
} catch (e) {
  console.error(e)
}

// eager validation mode for vee-validate
configure({ mode: 'eager' })
