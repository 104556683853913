import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import icons from './icons'
import Ripple from 'vuetify/lib/directives/ripple'

Vue.use(Vuetify, {
  directives: {
    Ripple
  }
})

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: '#49817B',
        secondary: '#B8860B',
        accent: '#FF5945',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        //buttons
        primaryButton: '#9F6652',
        secondaryButton: '#C4C4C4',
        actionButton: '#41695b',
        outlinedButton: '#ffffff'
      }
    }
  },
  directives: {
    Ripple
  },
  icons
})
