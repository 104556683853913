import Vue from 'vue'
import * as Sentry from '@sentry/vue'
import router from '@/router/router'

if (process.env.VUE_APP_SENTRY_DSN) {
  Sentry.init({
    Vue,
    environment: process.env.VUE_APP_ENVIRONMENT,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    tracesSampleRate: process.env.VUE_APP_ENVIRONMENT === 'production' ? 0.1 : 0,
    replaysOnErrorSampleRate: process.env.VUE_APP_ENVIRONMENT === 'production' ? 1.0 : 0,
    ignoreErrors: [
      // ignore Trengo errors
      "Cannot read properties of null (reading 'document')",
      "null is not an object (evaluating 'window.parent.document')",
      'i.onVisibilityChange(widget)',
      'this.ticket is null',
      'Cannot read properties of null',
      'chatIsStarted(widget)',
      // navigation errors related to offer/orders page
      'NavigationDuplicated',
      'Avoided redundant navigation to current location',
      'Navigation cancelled from',
      // old bundle files that are missing, this has been solved by
      // forcing a refresh of the page when this occurs. Check
      // router/router.js onError for the current solution.
      "Unexpected token '<'",
      "expected expression, got '<'",
      'Redirected when going from',
      'Loading CSS chunk'
    ],
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router)
      }),
      new Sentry.Replay()
    ]
  })
} else {
  console.log('Sentry DSN is not provided. Sentry integration init skipped')
}
