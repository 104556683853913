import { setFabricImage, tryExceptAwait } from '@/utils'
import fabricsEndpoints from '@/api/fabrics'
import { EXISTING_VADAIN_FABRICS, EXISTING_CUSTOMER_FABRICS, USER } from '@/constants'
import filters from './filters'

// initial state
const getDefaultState = () => {
  return {
    // fabrics: the list of user or vadain fabrics
    fabrics: [],
    // fabricDetails: key value store of detailed fabric
    // information, with the key being the fabric uuid
    fabricDetails: {},
    // fabricOption: the fabric type chosen by the user,
    // can either be user fabrics or vadain fabrics
    fabricOption: EXISTING_VADAIN_FABRICS,
    // count: the amount of fabrics
    count: null
  }
}

// getters
const getters = {
  getFabrics(state) {
    return state.fabrics
  },
  getFabricDetails(state) {
    return state.fabricDetails
  },
  getCount(state) {
    return state.count
  },
  getFabricOption(state) {
    return state.fabricOption
  }
}

// mutations
const mutations = {
  setFabricOption(state, fabricOption) {
    if (state.fabricOption !== fabricOption) {
      state.fabrics = []
    }
    state.fabricOption = fabricOption
  },
  setFabrics(state, fabrics) {
    state.fabrics = fabrics
  },
  setFabricDetails(state, fabricDetails) {
    state.fabricDetails = {
      ...state.fabricDetails,
      [fabricDetails.fabric_uuid]: fabricDetails
    }
  },
  setCount(state, count) {
    state.count = count
  }
}

// actions
const actions = {
  setFabricOption({ commit }, fabricOption) {
    commit('setFabricOption', fabricOption)
  },
  setFabricOptionByUser({ commit }, fabric) {
    commit(
      'setFabricOption',
      fabric.includes(USER) ? EXISTING_CUSTOMER_FABRICS : EXISTING_VADAIN_FABRICS
    )
  },
  resetFabrics({ commit }) {
    commit('setFabrics', [])
  },
  async loadFabrics({ commit, state }, { queryParams }) {
    // fetch fabrics based on fabric option
    let response = null
    response =
      state.fabricOption === EXISTING_CUSTOMER_FABRICS
        ? await tryExceptAwait(fabricsEndpoints.getCustomerFabricsList, [queryParams])
        : await tryExceptAwait(fabricsEndpoints.getVadainFabricsList, [queryParams])
    if (response.data) {
      // set fabric images
      for (const fabric of response.data?.results || []) {
        await setFabricImage(fabric, state.fabricOption)
      }
      // update state with response
      commit('setFabrics', response.data.results)
      commit('setCount', response.data.count)
    }
  },
  async loadFabricDetails({ commit, state }, { urlParams, queryParams, force = false }) {
    if (!force && urlParams.fabricUuid in state.fabricDetails) {
      // do not do anything if fabric details already present in state
      return
    }
    // fetch fabric details based on fabric option
    const response =
      state.fabricOption === EXISTING_CUSTOMER_FABRICS
        ? await tryExceptAwait(fabricsEndpoints.getCustomerFabricDetails, [
            urlParams,
            queryParams
          ])
        : await tryExceptAwait(fabricsEndpoints.getVadainFabricDetails, [
            urlParams,
            queryParams
          ])
    if (response.data) {
      // set fabric images
      await setFabricImage(response.data, state.fabricOption)
      // update state with response
      commit('setFabricDetails', response.data)
    }
  }
}

export default {
  namespaced: true,
  modules: {
    filters
  },
  state: getDefaultState,
  getters,
  mutations,
  actions
}
