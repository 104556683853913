import { computed } from 'vue'
import { VIDEO_MAPPING } from '@/constants'
import { useNamespacedGetters } from 'vuex-composition-helpers'

export default function () {
  const { getGroupName } = useNamespacedGetters('user', ['getGroupName'])

  const videoList = computed(() => {
    return VIDEO_MAPPING[getGroupName.value] || []
  })

  const hasVideos = computed(() => {
    return videoList.value.length > 0
  })

  return {
    videoList,
    hasVideos
  }
}
