import apiClient from './index'

const baseTokenPath = 'easyorder/token/'
const addressBookPath = 'api/customers/address-book/'
const userBasePath = 'api/user/'

const getUserPath = (path = '') => {
  return `${userBasePath}${path}`
}
const userSettingsPath = getUserPath('settings/')
const userPasswordUpdatePath = getUserPath('update-password/')
const userPleatCurtainPath = getUserPath('pleatcurtain/')
const forgotPasswordPath = getUserPath('recover-password/')
const recoverPasswordPath = getUserPath('recover-password/update')
const fabricSuppliersPath = `api/customer-fabrics/suppliers/`
const debtorsListPath = 'api/debtors/'

export default {
  async obtainTokenPair(data) {
    return await apiClient.post(`${baseTokenPath}`, data)
  },

  async refreshToken(data) {
    return await apiClient.post(`${baseTokenPath}refresh/`, data)
  },

  async verifyToken(data) {
    return await apiClient.post(`${baseTokenPath}verify/`, data)
  },

  async loadFabricSuppliers() {
    return await apiClient.get(fabricSuppliersPath)
  },

  async loadSettings() {
    return await apiClient.get(getUserPath('settings/'))
  },

  async updateSettings(data) {
    return await apiClient.put(userSettingsPath, data)
  },

  async updatePassword(data) {
    return await apiClient.put(userPasswordUpdatePath, data)
  },

  async forgotPassword(data) {
    return await apiClient.post(forgotPasswordPath, data)
  },

  async recoverPassword(data) {
    return await apiClient.post(recoverPasswordPath, data)
  },

  async updatePleatCurtainSettings(data) {
    return await apiClient.put(userPleatCurtainPath, data)
  },
  async updateUserSettings(data) {
    return await apiClient.patch(userSettingsPath, data)
  },
  //ADDRESS BOOK
  async addressBook(params) {
    return await apiClient.get(addressBookPath, { params })
  },
  async addAddress(data) {
    return await apiClient.post(addressBookPath, data)
  },
  async putAddress(data, id) {
    return await apiClient.put(`/${addressBookPath}${id}/`, data)
  },
  async deleteAddress(id) {
    return await apiClient.delete(`${addressBookPath}/${id}/`)
  },
  async loadDebtors(data) {
    return await apiClient.get(debtorsListPath, { params: data })
  },
  async loadDebtor(id) {
    return await apiClient.get(`${debtorsListPath}${id}/`)
  }
}
