import apiClient from './index'

export default {
  async getFabricFilters() {
    return await apiClient.get('api/vadain-fabrics/filters/')
  },
  async getRailRodPartsFilters() {
    return await apiClient.get('api/accessories/accessory-parts/filters')
  }
}
