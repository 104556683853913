export const statusState = {
  status: 0 // 0 -default, 1 -pending, 2 -success, 3 -error, 4 -forbidden
}

export const statusMutations = {
  statusPending(state) {
    state.status = 1
  },

  statusSuccess(state) {
    state.status = 2
  },

  statusError(state) {
    state.status = 3
  },

  statusForbidden(state) {
    state.status = 4
  },

  statusDefault(state) {
    state.status = 0
  }
}

// actions
export const statusActions = {
  revertStatus({ commit }) {
    commit('statusDefault')
  }
}
