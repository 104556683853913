import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import ssFabrics from 'vadain-sample-service/src/store/modules/ssFabrics'
import ssOrder from 'vadain-sample-service/src/store/modules/ssOrder'
import ssLocations from 'vadain-sample-service/src/store/modules/ssLocations'

import order from './modules/order'
import user from './modules/user'
import fabrics from './modules/fabrics'
import railrodparts from './modules/railrodparts'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

const store = new Vuex.Store({
  modules: {
    // sample service modules
    ssFabrics,
    ssOrder,
    ssLocations,
    // easyorder modules
    order,
    user,
    fabrics: fabrics,
    linings: fabrics,
    railrodparts
  },
  strict: debug,
  plugins: [
    createPersistedState({
      key: 'easyorder-store',
      paths: [
        'user',
        'order.room',
        'order.curtainOrderNumber',
        'order.railRodOrderNumber',
        'order.topbar'
      ],
      fetchBeforeUse: true
    })
  ]
})

export default store
