import DashboardIcon from '../components/Icons/SideBarIcons/DashboardIcon'
import CurtainBreezeIcon from '../components/Icons/SideBarIcons/CurtainBreezeIcon'
import CurtainIcon from '../components/Icons/SideBarIcons/CurtainIcon'
import OfferIcon from '../components/Icons/SideBarIcons/OfferIcon'
import OrderIcon from '../components/Icons/SideBarIcons/OrderIcon'
import SampleServiceIcon from '../components/Icons/SideBarIcons/SampleServiceIcon'
import CustomerFabricsIcon from '../components/Icons/SideBarIcons/CustomerFabricsIcon'
import HelpIcon from '../components/Icons/SideBarIcons/HelpIcon'
import ServiceIcon from '../components/Icons/SideBarIcons/ServiceIcon'
import TrackIcon from '../components/Icons/SideBarIcons/TrackIcon'
import SettingsIcon from '../components/Icons/SideBarIcons/SettingsIcon'
import NotesIcon from '@/components/Icons/SideBarIcons/NotesIcon.vue'

import IronLowIcon from '../components/Icons/Vadain/IronLowIcon'
import IronMediumIcon from '../components/Icons/Vadain/IronMediumIcon'
import IronHighIcon from '../components/Icons/Vadain/IronHighIcon'
import IronNotIcon from '../components/Icons/Vadain/IronNotIcon'

import InBleach from '../components/Icons/Vadain/InBleach'
import InColdBleach from '../components/Icons/Vadain/InColdBleach'
import InDontBleach from '../components/Icons/Vadain/InDontBleach'

import InClearA from '../components/Icons/Vadain/InClearA'
import InClearF from '../components/Icons/Vadain/InClearF'
import InClearP from '../components/Icons/Vadain/InClearP'
import InClearSpecF from '../components/Icons/Vadain/InClearSpecF'
import InClearSpecP from '../components/Icons/Vadain/InClearSpecP'
import InDontClear from '../components/Icons/Vadain/InDontClear'

import InDontDry from '../components/Icons/Vadain/InDontDry'
import InDry from '../components/Icons/Vadain/InDry'
import InNormalDry from '../components/Icons/Vadain/InNormalDry'
import InSensitiveDry from '../components/Icons/Vadain/InSensitiveDry'

import InAnticreaseWash30 from '../components/Icons/Vadain/InAnticreaseWash30'
import InAnticreaseWash40 from '../components/Icons/Vadain/InAnticreaseWash40'
import InAnticreaseWash60 from '../components/Icons/Vadain/InAnticreaseWash60'
import InDontWash from '../components/Icons/Vadain/InDontWash'
import InHandWash from '../components/Icons/Vadain/InHandWash'
import InNormalWash30 from '../components/Icons/Vadain/InNormalWash30'
import InNormalWash40 from '../components/Icons/Vadain/InNormalWash40'
import InNormalWash60 from '../components/Icons/Vadain/InNormalWash60'
import InNormalWash95 from '../components/Icons/Vadain/InNormalWash95'
import InWoolWash40 from '../components/Icons/Vadain/InWoolWash40'

import FlameRetardant from '../components/Icons/Vadain/FlameRetardant'
import EcoTex from '../components/Icons/Vadain/EcoTex'
import Cycled from '../components/Icons/Vadain/Cycled'
import ReturnProduct from '@/components/Icons/Vadain/ReturnProduct.vue'

const makeIcon = (component, name) => ({
  component,
  props: {
    name
  }
})

export default {
  values: {
    // replace vuetify defaults
    dropdown: 'mdi-chevron-down',
    expand: 'mdi-chevron-down',

    // sidebar menu
    dashboard: makeIcon(DashboardIcon, 'eo-dashboard'),
    products: makeIcon(CurtainIcon, 'eo-curtain'),
    offer: makeIcon(OfferIcon, 'eo-offer'),
    order: makeIcon(OrderIcon, 'eo-order'),
    sampleservice: makeIcon(SampleServiceIcon, 'eo-sampleservice'),
    'customer-fabrics': makeIcon(CustomerFabricsIcon, 'eo-customer-fabrics'),
    help: makeIcon(HelpIcon, 'eo-help'),
    'release-notes': makeIcon(NotesIcon, 'eo-notes'),
    track: makeIcon(TrackIcon, 'eo-track'),
    service: makeIcon(ServiceIcon, 'eo-service'),
    'curtain-breeze': makeIcon(CurtainBreezeIcon, 'eo-curtain-breeze'),
    settings: makeIcon(SettingsIcon, 'eo-settings'),
    'fabric-collection-images': makeIcon(CustomerFabricsIcon, 'eo-customer-fabrics'),

    //  Vadain wash icons
    washMock: makeIcon(DashboardIcon, 'vadain-mock'),

    inBleach: makeIcon(InBleach, 'vadain-in-bleach'),
    inColdBleach: makeIcon(InColdBleach, 'vadain-in-cold-bleach'),
    inDontBleach: makeIcon(InDontBleach, 'vadain-in-dont-bleach'),

    //  Vadain iron icons
    ironLow: makeIcon(IronLowIcon, 'vadain-iron-low'),
    ironMedium: makeIcon(IronMediumIcon, 'vadain-iron-medium'),
    ironHigh: makeIcon(IronHighIcon, 'vadain-iron-high'),
    ironNot: makeIcon(IronNotIcon, 'vadain-not-iron'),

    //  Vadain clear icons
    inClearA: makeIcon(InClearA, 'vadain-in-clear-a'),
    inClearF: makeIcon(InClearF, 'vadain-in-clear-f'),
    inClearP: makeIcon(InClearP, 'vadain-in-clear-p'),
    inClearSpecF: makeIcon(InClearSpecF, 'vadain-in-clear-spec-f'),
    inClearSpecP: makeIcon(InClearSpecP, 'vadain-in-clear-spec-p'),
    inDontClear: makeIcon(InDontClear, 'vadain-in-dont-clear'),

    //  Vadain dry icons
    inDontDry: makeIcon(InDontDry, 'vadain-in-dont-dry'),
    inDry: makeIcon(InDry, 'vadain-in-dry'),
    inNormalDry: makeIcon(InNormalDry, 'vadain-in-normal-dry'),
    inSensitiveDry: makeIcon(InSensitiveDry, 'vadain-in-sensitive-dry'),

    //  Vadain wash icons
    inAnticreaseWash30: makeIcon(InAnticreaseWash30, 'vadain-in-anticrease-wash-30'),
    inAnticreaseWash40: makeIcon(InAnticreaseWash40, 'vadain-in-anticrease-wash-40'),
    inAnticreaseWash60: makeIcon(InAnticreaseWash60, 'vadain-in-anticrease-wash-60'),
    inDontWash: makeIcon(InDontWash, 'vadain-in-dont-wash'),
    inHandWash: makeIcon(InHandWash, 'vadain-in-hand-wash'),
    inNormalWash30: makeIcon(InNormalWash30, 'vadain-in-normal-wash-30'),
    inNormalWash40: makeIcon(InNormalWash40, 'vadain-in-normal-wash-40'),
    inNormalWash60: makeIcon(InNormalWash60, 'vadain-in-normal-wash-60'),
    inNormalWash95: makeIcon(InNormalWash95, 'vadain-in-normal-wash-95'),
    inWoolWash40: makeIcon(InWoolWash40, 'vadain-in-wool-wash-40'),

    flameRetardant: makeIcon(FlameRetardant, 'vadain-flame-retardant'),
    ecoTex: makeIcon(EcoTex, 'vadain-eco-tex'),
    cycled: makeIcon(Cycled, 'vadain-cycled'),
    'return-product': makeIcon(ReturnProduct, 'return-product')
  }
}
