<template>
  <v-overlay opacity="0.9" :value="showOverlay" :dark="dark" z-index="6">
    <v-btn
      v-if="showCloseButton"
      id="overlay-close-btn"
      fixed
      outlined
      rounded
      top
      right
      min-height="50px"
      text
      data-test="closeOverlayButton"
      @click="closeOverlay"
    >
      {{ t('components.overlay.close') }}
      <v-icon x-large class="ml-4"> mdi-close-circle-outline </v-icon>
    </v-btn>
    <slot />
  </v-overlay>
</template>

<script setup>
import { useI18n } from 'vue-i18n-composable'

//data
defineProps({
  showOverlay: {
    type: Boolean,
    default: false,
    description: 'Is overlay visible'
  },
  dark: {
    type: Boolean,
    default: true
  },
  showCloseButton: {
    type: Boolean,
    default: true,
    description: 'Show close button'
  }
})

//state
const { t } = useI18n()

//methods
const closeOverlay = () => {
  emit('closeOverlay')
}

const emit = defineEmits(['closeOverlay'])
</script>

<style lang="scss">
#overlay-close-btn {
  font-size: 16px;
  color: #fff;
  text-transform: capitalize !important;
  border: 0;
}

.v-overlay .theme--dark.v-text-field > .v-input__control > .v-input__slot::before,
.v-input__slot::after {
  border: none !important;
}
</style>
