import { computed } from 'vue'
import { keys } from 'lodash-es'
import { useNamespacedGetters } from 'vuex-composition-helpers'
import { DOWNLOAD_MAPPING, DOWNLOAD_CATEGORY_PRICE_LISTS } from '@/constants'

export default function () {
  const { getGroupName, getShowPriceLists } = useNamespacedGetters('user', [
    'getGroupName',
    'getShowPriceLists'
  ])

  const downloadList = computed(() => {
    const groupNameWithFallback =
      Object.keys(DOWNLOAD_MAPPING).indexOf(getGroupName.value) === -1
        ? 'Default' // fallback
        : getGroupName.value
    const selectedDownloadList = DOWNLOAD_MAPPING[groupNameWithFallback]
    if (!getShowPriceLists.value) {
      delete selectedDownloadList[DOWNLOAD_CATEGORY_PRICE_LISTS]
    }
    return selectedDownloadList
  })

  const downloadCategories = computed(() => keys(downloadList.value))

  const hasDownloads = computed(() => {
    return downloadList.value.length > 0
  })

  return {
    downloadList,
    downloadCategories,
    hasDownloads
  }
}
