// initial state
const state = {
  // on url query param it calls `type`: [Array of string].
  typeFilters: [],
  // on url query param it is called `production_status`: String
  statusFilter: '',
  // for now it have ordering by date.
  ordering: {},
  // search bar value, on url it is `q`.
  query: '',
  ownCreatedOrders: false,
  activeTab: 0
}

// getters
const getters = {
  getTypeFilters(state) {
    return state.typeFilters
  },
  getStatusFilter(state) {
    return state.statusFilter
  },
  getOrdering(state) {
    return state.ordering
  },
  getSearchQuery(state) {
    return state.query
  },
  getOwnCreatedOrders(state) {
    return state.ownCreatedOrders
  },
  getQueryParams(state) {
    const params = {
      q: state.query
    }
    if (state.typeFilters) {
      params['type'] = state.typeFilters.join()
    }
    if (state.statusFilter) {
      params['production_status'] = state.statusFilter
    }
    if (state.ordering) {
      params['ordering'] = state.ordering
    }
    return params
  },
  getActiveTab(state) {
    return state.activeTab
  }
}

// mutationS
const mutations = {
  setTypeFilters(state, filters) {
    state.typeFilters = filters
  },
  setStatusFilter(state, filter) {
    state.statusFilter = filter
  },
  setOrdering(state, ordering) {
    state.ordering = ordering
  },
  setSearchQuery(state, query) {
    state.query = query
  },
  setOwnCreatedOrders(state, val) {
    state.ownCreatedOrders = val
  },
  storeQueryParams(state, query) {
    state.typeFilters = []
    state.statusFilter = ''
    if (query.type && query.type !== '') {
      state.typeFilters = query.type.split(',')
    }
    if (query.production_status && query.production_status !== '') {
      state.statusFilter = query.production_status
    }
    state.ordering = query.ordering
    state.query = query.q
  },
  setActiveTab(state, activeTab) {
    return (state.activeTab = activeTab)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations
}
