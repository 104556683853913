import apiClient from './index'
import { cleanFabricUuid } from '@/utils'

const vadainFabricsBasePath = `api/vadain-fabrics/`
const customerFabricsBasePath = `api/customer-fabrics/`

export default {
  async getVadainFabricsList(queryParams) {
    return await apiClient.get(vadainFabricsBasePath, { params: queryParams })
  },

  async getVadainFabricFamilyDetails(familyId, queryParams) {
    return await apiClient.get(`${vadainFabricsBasePath}family/${familyId}/`, {
      params: queryParams
    })
  },

  async getVadainFabricDetails({ fabricUuid }, queryParams) {
    fabricUuid = cleanFabricUuid(fabricUuid)
    return await apiClient.get(`${vadainFabricsBasePath}${fabricUuid}/`, {
      params: queryParams
    })
  },

  async getCustomerFabricsList(queryParams) {
    return await apiClient.get(customerFabricsBasePath, { params: queryParams })
  },

  async getCustomerFabricDetails({ fabricUuid }, queryParams) {
    fabricUuid = cleanFabricUuid(fabricUuid)
    return await apiClient.get(`${customerFabricsBasePath}${fabricUuid}/`, {
      params: queryParams
    })
  },

  async deleteCustomerFabric({ fabricUuid }, queryParams) {
    fabricUuid = cleanFabricUuid(fabricUuid)
    return await apiClient.delete(`${customerFabricsBasePath}${fabricUuid}/`, {
      params: queryParams
    })
  },

  async createCustomerFabric(form, queryParams) {
    return await apiClient.post(customerFabricsBasePath, form, { params: queryParams })
  },

  async duplicateCustomerFabric({ fabricUuid }, queryParams) {
    fabricUuid = cleanFabricUuid(fabricUuid)
    return await apiClient.post(`${customerFabricsBasePath}${fabricUuid}/duplicate/`, {
      params: queryParams
    })
  },

  async editCustomerFabric({ fabricUuid }, form, queryParams) {
    fabricUuid = cleanFabricUuid(fabricUuid)
    return await apiClient.put(`${customerFabricsBasePath}${fabricUuid}/`, form, {
      params: queryParams
    })
  },

  async uploadCustomerFabricImages({ fabricUuid }, image, queryParams) {
    fabricUuid = cleanFabricUuid(fabricUuid)
    return await apiClient.patch(
      `${customerFabricsBasePath}${fabricUuid}/update-image/`,
      image,
      {
        headers: {
          'Content-Type': 'form/multipart'
        },
        params: queryParams
      }
    )
  },

  async createFabricPrices({ fabricUuid }, form, queryParams) {
    fabricUuid = cleanFabricUuid(fabricUuid)
    return await apiClient.post(
      `${customerFabricsBasePath}${fabricUuid}/prices/add/`,
      form,
      { params: queryParams }
    )
  }
}
