import { createI18n } from 'vue-i18n-composable'
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { extend } from 'vee-validate'
import {
  required,
  numeric,
  double,
  email,
  alpha_num,
  alpha,
  min_value,
  max_value,
  max,
  min,
  regex,
  confirmed
} from 'vee-validate/dist/rules'
import { deepCopy } from 'vadain-sample-service/src/utils'
import merge from 'deepmerge'

import enVee from 'vee-validate/dist/locale/en'
import nlVee from 'vee-validate/dist/locale/nl'
import deVee from 'vee-validate/dist/locale/de'
import plVee from 'vee-validate/dist/locale/pl'
import frVee from 'vee-validate/dist/locale/fr'
import ltVee from 'vee-validate/dist/locale/lt'

import enSS from 'vadain-sample-service/src/locales/en'
import nlSS from 'vadain-sample-service/src/locales/nl'
import deSS from 'vadain-sample-service/src/locales/de'
import plSS from 'vadain-sample-service/src/locales/pl'
import ltSS from 'vadain-sample-service/src/locales/lt'

const veeMsgs = {
  nl: nlVee,
  'en-au': enVee,
  en: enVee,
  de: deVee,
  pl: plVee,
  fr: frVee,
  lt: ltVee
}

const ssMsgs = {
  nl: nlSS,
  en: enSS,
  'en-au': enSS,
  de: deSS,
  pl: plSS,
  lt: ltSS
}

function loadLocaleMessages() {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]

      if (ssMsgs[locale]) {
        // merge easyorder and sample service locales
        messages[locale] = merge(ssMsgs[locale], locales(key))
      } else {
        // only easyorder locales
        messages[locale] = locales(key)
      }
      if (veeMsgs[locale]) {
        // add custom validation messages
        messages[locale]['validations'] = merge(
          deepCopy(veeMsgs[locale].messages), // default veevalidate translations
          messages[locale]['validations'] // custom translations, should be leading
        )
      }
    }
  })
  return messages
}

export function localeSafe(value) {
  return typeof value === 'string' ? value.replace(/\./g, '') : value
}

const defaultMessage = (field, values) => {
  return i18n.t(`validations.${values._rule_}`, values)
}

const regexMessage = (field, values) => {
  return i18n.t(`validations.${values._rule_}.${field}`, values)
}

Vue.use(VueI18n)
const i18n = createI18n({
  locale: navigator.language || 'en-au',
  fallbackLocale: 'en-au',
  silentTranslationWarn: true,
  silentFallbackWarn: true,
  messages: loadLocaleMessages()
})

extend('required', {
  ...required,
  message: defaultMessage
})

extend('numeric', {
  ...numeric,
  message: defaultMessage
})

extend('email', {
  ...email,
  message: defaultMessage
})

extend('alpha', {
  ...alpha,
  message: defaultMessage
})

extend('alpha_num', {
  ...alpha_num,
  message: defaultMessage
})

extend('double', {
  ...double,
  validate: (value, params) => {
    return numeric.validate(value) || double.validate(value, params)
  },
  message: defaultMessage
})

extend('min_value', {
  ...min_value,
  message: defaultMessage
})

extend('max_value', {
  ...max_value,
  message: defaultMessage
})

extend('max', {
  ...max,
  message: defaultMessage
})

extend('min', {
  ...min,
  message: defaultMessage
})

extend('regex', {
  ...regex,
  message: regexMessage
})

extend('confirmed', {
  ...confirmed,
  message: regexMessage
})

export default i18n
