<template>
  <router-view />
</template>

<script>
export default {
  name: 'App',

  data: () => ({
    //
  }),
  async mounted() {
    await this.$store.dispatch('user/restoreAuthApiClient')
  }
}
</script>
<style lang="scss">
@import 'styles/custom';
</style>
