import apiClient from '@/api'
import userEndpoints from '@/api/user'
import orderEndpoints from '@/api/orders'
import { mapValues } from 'lodash-es'
import { tryExceptAwait } from '@/utils'
import { statusState, statusMutations, statusActions } from '@/store/utils/status'
import { TOKEN_LIFE_TIMEOUT } from '@/constants'
import router from '@/router/router'

let tokenAutoRefreshTimeout

// initial state
const state = {
  token: '',
  refresh: '',
  rememberLogin: false,
  debtor: {},
  employees: [],
  employee: '',
  group_name: '',
  easyorder_logo: null,
  curtainTypes: [],
  pleatTypes: [],
  defaultPleatTypes: [],
  basic_tier_size_default: [],
  comfort_tier_size_default: [],
  hautecouture_tier_size_default: [],
  deliver_with_runners_default: true,
  pleat_curtain_head_size_default: 0.0,
  ring_curtain_head_size_default: 0.0,
  span_curtain_head_size_default: 0.0,
  rod_curtain_head_size_default: 0.0,
  fronslint_curtain_head_size_default: 0.0,
  has_delivery_address: false,
  has_customer_fabrics: true,
  has_vadain_fabrics: true,
  luxuries: [],
  systemSettings: {
    available_reports: [],
    child_safety_default: undefined,
    curtain_types: [],
    has_decorative_rail: true,
    has_decorative_rod: true,
    has_electric_rail: true,
    has_track_rail: true,
    has_rail_curve_disclaimer: false,
    price_type_default: undefined,
    preferred_language: 'nl',
    enter_width_before_height_setting: true,
    customer_support_email: '',
    customer_support_phone: '',
    has_lining_by_default: false,
    has_pull_rod: true,
    has_rail_rod: true,
    has_rail_rod_parts: true,
    show_only_tracking_page: false,
    show_sidebar_track_button: true,
    show_sidebar_customer_fabrics_button: true,
    show_sidebar_service_account_button: true,
    show_sidebar_release_notes_button: true,
    show_sample_service_buttons: true,
    show_sidebar_settings_button: true,
    show_side_option: true,
    show_tier_size: true,
    show_nap_orientation: true,
    show_order_memo: true,
    show_room: true,
    show_round_to_pattern: true,
    show_strokes: true,
    show_assembly_height: true,
    show_control_length: true,
    show_employee: true,
    show_head_size_for_curtain_types: [],
    show_hem_type: true,
    show_panel_rail_memo_instructions: false,
    show_pleat_percentage: true,
    show_side_hem_type: true,
    show_make_set_button: true,
    show_unmatch_strokes_button: true,
    show_tracking_order_status: true,
    show_fabric_prices: true,
    track_all_shops: false,
    show_plan_date: false
  },
  ironing_default: false,
  suppliers: [],
  allDebtors: [],
  shopDebtors: [],
  isServiceAccount: false,
  isShopAccount: true,
  servicingDebtor: {},
  hiddenSwitchPrice: false,
  showCurtainSettings: true,
  showFoldCurtainSettings: true,
  showPriceLists: true,
  shopName: 'default',
  defaultPageName: 'dashboard',
  hasCollapsibleSidebar: false,
  flowViewType: 'normal',
  ...statusState
}

const getters = {
  getDebtor(state) {
    return state.debtor
  },
  getEmployee(state) {
    return state.employee
  },
  getGroupName(state) {
    return state.group_name
  },
  getEmployees(state) {
    return state.employees
  },
  getStatus(state) {
    return state.status
  },
  getToken(state) {
    return state.token
  },
  getRememberLogin(state) {
    return state.rememberLogin
  },
  isLogged(state) {
    return state.status === 2 && !!state.token
  },
  getEasyorderLogo(state) {
    return state.easyorder_logo
  },
  getCurtainTypes(state) {
    return state.curtainTypes
  },
  getFabricSuppliers(state) {
    return state.suppliers
  },
  getBasicTierSize(state) {
    return state.basic_tier_size_default
  },
  getComfortTierSize(state) {
    return state.comfort_tier_size_default
  },
  getHautecoutureTierSize(state) {
    return state.hautecouture_tier_size_default
  },
  getDeliverWithRunners(state) {
    return state.deliver_with_runners_default
  },
  getPleatCurtainHeadSize(state) {
    return state.pleat_curtain_head_size_default
  },
  getRingCurtainHeadSize(state) {
    return state.ring_curtain_head_size_default
  },
  getSpanCurtainHeadSize(state) {
    return state.span_curtain_head_size_default
  },
  getRodCurtainHeadSize(state) {
    return state.rod_curtain_head_size_default
  },
  getFronslintCurtainHeadSize(state) {
    return state.fronslint_curtain_head_size_default
  },
  getHiddenSwitchPrice(state) {
    return state.hiddenSwitchPrice
  },
  getLuxuries(state) {
    return state.luxuries
  },
  getPleatTypes(state) {
    return state.pleatTypes
  },
  getPriceType(state) {
    return state.systemSettings['price_type_default'] === 'wholesaleprice'
      ? 'wholesale_price'
      : 'price'
  },
  getSystemLanguage(state) {
    return state.systemSettings['preferred_language']
  },
  getHasCollapsibleSidebar(state) {
    return state.hasCollapsibleSidebar
  },
  getHasDeliveryAddress(state) {
    return state.has_delivery_address
  },
  getHasCustomerFabrics(state) {
    return state.has_customer_fabrics
  },
  getHasVadainFabrics(state) {
    return state.has_vadain_fabrics
  },
  getSystemSettings(state) {
    return state.systemSettings
  },
  getIroningDefault(state) {
    return state.ironing_default
  },
  getAllDebtors(state) {
    return state.allDebtors
  },
  getShopDebtors(state) {
    return state.shopDebtors
  },
  isServiceAccount(state) {
    return state.isServiceAccount
  },
  isShopAccount(state) {
    return state.isShopAccount
  },
  getServicingDebtor(state) {
    return state.servicingDebtor
  },
  getShowCurtainSettings(state) {
    return state.showCurtainSettings
  },
  getShowFoldCurtainSettings(state) {
    return state.showFoldCurtainSettings
  },
  getShowPriceLists(state) {
    return state.showPriceLists
  },
  getShopName(state) {
    return state.shopName
  },
  getDefaultPageName(state) {
    return state.defaultPageName
  }
}

// mutations
const mutations = {
  setDebtor(state, debtor) {
    state.debtor = debtor
  },
  setEmployee(state, employee) {
    state.employee = employee
  },
  setEmployees(state, employees) {
    state.employees = employees
  },
  setToken(state, token) {
    state.token = token
  },
  setRefreshToken(state, refreshToken) {
    state.refresh = refreshToken
  },
  setRememberLogin(state, rememberLogin) {
    state.rememberLogin = rememberLogin
  },
  updateAuthApiClient(state, token) {
    apiClient.defaults.headers['authorization'] = `Bearer ${token}`
  },
  clearToken(state) {
    state.token = ''
    state.refresh = ''
    delete apiClient.defaults.headers['authorization']
  },
  setEasyorderLogo(state, easyorder_logo) {
    state.easyorder_logo = easyorder_logo
  },
  setCurtainTypes(state, curtainTypes) {
    state.curtainTypes = curtainTypes
  },
  setSuppliers(state, suppliers) {
    state.suppliers = [...suppliers]
  },
  setBasicTierSize(state, basic_tier_size_default) {
    state.basic_tier_size_default = basic_tier_size_default
  },
  setComfortTierSize(state, comfort_tier_size_default) {
    state.comfort_tier_size_default = comfort_tier_size_default
  },
  setHautecoutureTierSize(state, hautecouture_tier_size_default) {
    state.hautecouture_tier_size_default = hautecouture_tier_size_default
  },
  setDeliverWithRunnersDefault(state, deliver_with_runners_default) {
    state.deliver_with_runners_default = deliver_with_runners_default
  },
  setPleatCurtainHeadSizeDefault(state, head_size_default) {
    state.pleat_curtain_head_size_default = head_size_default
  },
  setRingCurtainHeadSizeDefault(state, head_size_default) {
    state.ring_curtain_head_size_default = head_size_default
  },
  setSpanCurtainHeadSizeDefault(state, head_size_default) {
    state.span_curtain_head_size_default = head_size_default
  },
  setRodCurtainHeadSizeDefault(state, head_size_default) {
    state.rod_curtain_head_size_default = head_size_default
  },
  setFronslintCurtainHeadSizeDefault(state, head_size_default) {
    state.fronslint_curtain_head_size_default = head_size_default
  },
  setHiddenSwitchPrice(state, hiddenSwitchPrice) {
    state.hiddenSwitchPrice = hiddenSwitchPrice
  },
  setLuxuries(state, luxuries) {
    state.luxuries = luxuries
  },
  setPleatTypes(state, pleatTypes) {
    state.pleatTypes = [...pleatTypes]
  },
  setDefaultPleatTypes(state, pleatTypes) {
    state.defaultPleatTypes = [...pleatTypes]
  },
  setHasDeliveryAddress(state, has_delivery_address) {
    state.has_delivery_address = has_delivery_address
  },
  setHasCustomerFabrics(state, has_customer_fabrics) {
    state.has_customer_fabrics = has_customer_fabrics
  },
  setHasVadainFabrics(state, has_vadain_fabrics) {
    state.has_vadain_fabrics = has_vadain_fabrics
  },
  updateSystemSetting(state, setting) {
    state.systemSettings = {
      ...state.systemSettings,
      ...setting
    }
  },
  setSystemSettings(state, systemSettings) {
    state.employee = systemSettings['default_employee_name']
    state.employees = systemSettings['employees']
    state.debtor = systemSettings['debtor']
    state.group_name = systemSettings['group_name']
    state.systemSettings = mapValues(state.systemSettings, (val, key) =>
      systemSettings[key] !== undefined ? systemSettings[key] : val
    )
    state.systemSettings['group_name'] = systemSettings['group_name']
    state.allDebtors = systemSettings['all_debtors'] || []
    state.shopDebtors = systemSettings['all_debtors'] || []
    state.isServiceAccount =
      systemSettings['is_service_account'] || state.allDebtors.length > 1
    state.isShopAccount = !systemSettings['is_service_account']
    state.servicingDebtor = state.isServiceAccount ? state.debtor : {}
  },
  setIroningDefault(state, ironing_default) {
    state.ironing_default = ironing_default
  },
  setAllDebtors(state, allDebtors) {
    state.allDebtors = allDebtors
  },
  setServicingDebtor(state, debtor) {
    state.servicingDebtor = debtor
  },
  setShowCurtainSettings(state, showCurtainSettings) {
    state.showCurtainSettings = showCurtainSettings
  },
  setShowFoldCurtainSettings(state, showFoldCurtainSettings) {
    state.showFoldCurtainSettings = showFoldCurtainSettings
  },
  setShowPriceLists(state, showPriceLists) {
    state.showPriceLists = showPriceLists
  },
  setShopName(state, shopName) {
    state.shopName = shopName
  },
  setHasCollapsibleSidebar(state, hasCollapsibleSidebar) {
    state.hasCollapsibleSidebar = hasCollapsibleSidebar
  },
  setFlowViewType(state, flowViewType) {
    state.flowViewType = flowViewType
  },
  setDefaultPageName(state, defaultPageName) {
    state.defaultPageName = defaultPageName
  },
  ...statusMutations
}

// actions
const actions = {
  async changeEmployee({ commit, dispatch, rootGetters }, employee) {
    // get latest draft order id from order module
    const orderId =
      rootGetters['order/getOrderId'] ||
      (await dispatch('order/getOrCreateDraftOrder', null, { root: true }))
    // update draft order with new employee
    const { status, response } = await tryExceptAwait(orderEndpoints.partialUpdateOrder, [
      { orderId },
      { employee }
    ])
    if (status !== 200) {
      console.error(response)
    }
    // save employee in user state
    commit('setEmployee', employee)
  },
  setEmployees({ commit }, employees) {
    commit('setEmployees', employees)
  },
  async logout({ commit }, redirectToLogin = true) {
    commit('clearToken')
    clearInterval(tokenAutoRefreshTimeout)
    if (redirectToLogin) {
      await router.push({ name: 'login' })
    }
  },
  async login({ commit, dispatch }, { data }) {
    commit('statusPending')
    await dispatch('logout', false)
    await dispatch('order/resetState', null, { root: true })
    await dispatch('fabrics/resetFabrics', null, { root: true })
    const response = await tryExceptAwait(userEndpoints.obtainTokenPair, [data])
    if (response.status === 200) {
      commit('setToken', response.data.access)
      // todo: add real remember login function
      commit('setRefreshToken', response.data.refresh)
      commit('updateAuthApiClient', response.data.access)
      tokenAutoRefreshTimeout = setTimeout(
        () => dispatch('refreshToken'),
        TOKEN_LIFE_TIMEOUT
      )
      await dispatch('loadSystemSettings')
      commit('statusSuccess')
    } else if (response.status === 401) {
      commit('statusForbidden')
    } else {
      commit('statusError')
      console.error(response)
    }
  },
  async refreshToken({ commit, state, dispatch }) {
    commit('statusPending')
    try {
      const { data, status } = await userEndpoints.refreshToken({
        refresh: state.refresh
      })
      if (status === 200) {
        await commit('setToken', data.access)
        await commit('updateAuthApiClient', data.access)
        await commit('statusSuccess')
        clearTimeout(tokenAutoRefreshTimeout)
        tokenAutoRefreshTimeout = setTimeout(
          () => dispatch('refreshToken'),
          TOKEN_LIFE_TIMEOUT
        )
      } else {
        commit('statusError')
        dispatch('logout')
      }
    } catch (e) {
      commit('statusError')
      dispatch('logout')
    }
    return state.status
  },

  restoreAuthApiClient({ state, commit }) {
    if (state.token) {
      commit('updateAuthApiClient', state.token)
    }
  },

  async loadFabricSuppliers({ commit }) {
    const { data, status } = await tryExceptAwait(userEndpoints.loadFabricSuppliers, [])
    if (status === 200) {
      commit('setSuppliers', data)
    } else {
      commit('setSuppliers', [{ id: 1, name: 'Into The Source' }])
    }
  },

  async loadSystemSettings({ commit }) {
    const { data, status } = await tryExceptAwait(userEndpoints.loadSettings, [])
    if (status === 200) {
      commit('setSystemSettings', data)
      commit('setIroningDefault', data['ironing_default'])
      commit('setCurtainTypes', data['curtain_types'])
      commit('setDefaultPleatTypes', data['default_pleat_types'])
      commit('setPleatTypes', data['available_pleat_types'])
      commit('setBasicTierSize', data['basic_tier_size_default'])
      commit('setComfortTierSize', data['comfort_tier_size_default'])
      commit('setHautecoutureTierSize', data['hautecouture_tier_size_default'])
      commit('setPleatCurtainHeadSizeDefault', data['pleat_curtain_head_size'])
      commit('setRingCurtainHeadSizeDefault', data['ring_curtain_head_size'])
      commit('setSpanCurtainHeadSizeDefault', data['span_curtain_head_size'])
      commit('setRodCurtainHeadSizeDefault', data['rod_curtain_head_size'])
      commit('setFronslintCurtainHeadSizeDefault', data['fronslint_curtain_head_size'])
      commit('setLuxuries', data['luxuries'])
      commit('setHiddenSwitchPrice', data['hidden_switch_price'])
      commit('setShowCurtainSettings', data['show_curtain_settings'])
      commit('setShowFoldCurtainSettings', data['show_fold_curtain_settings'])
      commit('setShowPriceLists', data['show_price_lists'])
      commit(
        'setDefaultPageName',
        data['show_only_tracking_page'] ? 'track' : 'dashboard'
      )
      commit('setHasDeliveryAddress', data['has_delivery_address'])
      commit('setHasCustomerFabrics', data['has_customer_fabrics'])
      commit('setHasVadainFabrics', data['has_vadain_fabrics'])
      commit('setShopName', data['shop_name'])
      commit('setEasyorderLogo', data['easyorder_logo'])
    } else {
      console.error('error on load account details')
    }
  },

  async updateSystemSettings({ commit }, systemSettings) {
    commit('statusPending')
    const form = { ...systemSettings, is_system_settings: true }
    const { data, status } = await tryExceptAwait(userEndpoints.updateSettings, [form])
    if (status === 200) {
      commit('setSystemSettings', data)
      commit('statusSuccess')
    } else {
      commit('statusError')
    }
  },

  async updateUserPleatTypes({ state, commit, dispatch }) {
    commit('statusPending')
    const form = { available_pleat_types: state.pleatTypes }
    const { status } = await tryExceptAwait(userEndpoints.updatePleatCurtainSettings, [
      form
    ])
    if (status === 200) {
      commit('statusSuccess')
      await dispatch('loadSystemSettings')
    } else {
      commit('statusError')
    }
  },

  async updateUserSettings({ state, commit, dispatch }) {
    commit('statusPending')
    const form = {
      basic_tier_size_default: state.basic_tier_size_default,
      comfort_tier_size_default: state.comfort_tier_size_default,
      hautecouture_tier_size_default: state.hautecouture_tier_size_default,
      deliver_with_runners_default: state.deliver_with_runners_default,
      pleat_curtain_head_size: state.pleat_curtain_head_size_default,
      ring_curtain_head_size: state.ring_curtain_head_size_default,
      span_curtain_head_size: state.span_curtain_head_size_default,
      rod_curtain_head_size: state.rod_curtain_head_size_default,
      fronslint_curtain_head_size: state.fronslint_curtain_head_size_default,
      ironing_default: state.ironing_default
    }
    const { status } = await tryExceptAwait(userEndpoints.updateUserSettings, [form])
    if (status === 200) {
      commit('statusSuccess')
      await dispatch('loadSystemSettings')
    } else {
      commit('statusError')
    }
  },

  updateServicingDebtor({ commit }, debtor) {
    commit('setServicingDebtor', debtor)
  },

  ...statusActions
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
