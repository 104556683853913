import i18n from '@/i18n'
import IndexLayout from '../views/Layout/IndexLayout.vue'
import BackofficeLayout from '../views/Layout/BackofficeLayout'

// Base pages
const LoginPage = () =>
  import(/* webpackChunkName: "login" */ '../views/Pages/Public/LoginPage')
const ForgotPasswordPage = () =>
  import(/* webpackChunkName: "login" */ '../views/Pages/Public/ForgotPasswordPage')
const RecoverPasswordPage = () =>
  import(/* webpackChunkName: "login" */ '../views/Pages/Public/RecoverPasswordPage')
const DashboardPage = () =>
  import(/* webpackChunkName: "dashboard" */ '../views/Pages/Dashboard/DashboardPage')
const AccountPage = () =>
  import(/* webpackChunkName: "service" */ '../views/Pages/Service/AccountMainPage')
const CurtainBreezePage = () =>
  import(
    /* webpackChunkName: "curtain-breeze" */ '../views/Pages/Products/CurtainBreezePage'
  )
const ProductsPage = () =>
  import(/* webpackChunkName: "products" */ '../views/Pages/Products/ProductsPage')
const CurtainPage = () =>
  import(/* webpackChunkName: "products" */ '../views/Pages/Products/CurtainPage')
const FoldCurtainPage = () =>
  import(/* webpackChunkName: "products" */ '../views/Pages/Products/FoldCurtainPage')
const RailRodPage = () =>
  import(/* webpackChunkName: "products" */ '../views/Pages/Products/RailRodPage')
const RailRodPartsPage = () =>
  import(/* webpackChunkName: "products" */ '../views/Pages/Products/RailRodPartsPage')
const SampleServicePage = () =>
  import(
    /* webpackChunkName: "sample-service" */ '../views/Pages/SampleService/SampleServicePage'
  )
const CustomerFabricsPage = () =>
  import(
    /* webpackChunkName: "customer-fabrics" */ '../views/Pages/CustomerFabrics/CustomerFabricsPage'
  )
const AddCustomerFabricPage = () =>
  import(
    /* webpackChunkName: "add-customer-fabric" */ '../views/Pages/CustomerFabrics/AddCustomerFabricPage'
  )
const OffersPage = () =>
  import(/* webpackChunkName: "offer" */ '../views/Pages/Offer/OffersPage')
const OrdersPage = () =>
  import(/* webpackChunkName: "order" */ '../views/Pages/Orders/OrdersPage')
const OrdersTrackingPage = () =>
  import(
    /* webpackChunkName: "order-tracking-page" */ '../views/Pages/Orders/OrdersTrackingPage'
  )
const SettingsPage = () =>
  import(/* webpackChunkName: "settings" */ '../views/Pages/Settings/SettingsPage')
const SupportPage = () =>
  import(/* webpackChunkName: "support" */ '../views/Pages/Help/SupportPage')
import ReleaseNotesPage from '@/views/Pages/ReleaseNotes/ReleaseNotesPage.vue'

const titleTemplate = 'Easyorder'

const routes = [
  {
    path: '/',
    component: IndexLayout,
    name: 'index',
    redirect: '/login',
    children: [
      {
        path: 'login',
        name: 'login',
        component: LoginPage,
        meta: {
          pageTitle: `Login page | ${titleTemplate}`
        }
      },
      {
        path: 'forgot-password',
        name: 'forgot-password',
        component: ForgotPasswordPage,
        meta: {
          pageTitle: `Forgot password | ${titleTemplate}`
        }
      },
      {
        path: 'recover-password',
        name: 'recover-password',
        component: RecoverPasswordPage,
        meta: {
          pageTitle: `Recovery user password | ${titleTemplate}`
        }
      }
    ]
  },
  {
    path: '/account',
    component: BackofficeLayout,
    name: 'account-index',
    redirect: '/account/dashboard',
    children: [
      {
        path: 'dashboard',
        name: 'dashboard',
        component: DashboardPage,
        meta: {
          currentMenu: {
            text: i18n.t('menu.dashboard'),
            name: 'dashboard'
          },
          pageTitle: `${i18n.t('menu.dashboard')} | ${titleTemplate}`
        }
      },
      {
        path: 'products',
        name: 'products',
        component: ProductsPage,
        meta: {
          currentMenu: {
            text: i18n.t('menu.products'),
            name: 'products'
          },
          pageTitle: `${i18n.t('menu.products')} | ${titleTemplate}`
        }
      },
      {
        path: 'products/curtain',
        name: 'curtain',
        component: CurtainPage,
        meta: {
          currentMenu: {
            text: i18n.t('menu.products'),
            name: 'products'
          },
          pageTitle: `${i18n.t('menu.products')} | ${titleTemplate}`,
          isDebtorServiceable: true
        }
      },
      {
        path: 'products/curtain/:articleOrderId/',
        name: 'curtain-edit',
        component: CurtainPage,
        props: true,
        meta: {
          currentMenu: {
            text: i18n.t('menu.products'),
            name: 'products'
          },
          pageTitle: `${i18n.t('menu.products')} | ${titleTemplate}`
        }
      },
      {
        path: 'products/foldcurtain',
        name: 'foldcurtain',
        component: FoldCurtainPage,
        meta: {
          currentMenu: {
            text: i18n.t('menu.products'),
            name: 'products'
          },
          pageTitle: `${i18n.t('menu.products')} | ${titleTemplate}`,
          isDebtorServiceable: true
        }
      },
      {
        path: 'products/foldcurtain/:articleOrderId/',
        name: 'foldcurtain-edit',
        component: FoldCurtainPage,
        props: true,
        meta: {
          currentMenu: {
            text: i18n.t('menu.products'),
            name: 'products'
          },
          pageTitle: `${i18n.t('menu.products')} | ${titleTemplate}`,
          isDebtorServiceable: true
        }
      },
      {
        path: 'products/railrod',
        name: 'railrod',
        component: RailRodPage,
        meta: {
          currentMenu: {
            text: i18n.t('menu.products'),
            name: 'products'
          },
          pageTitle: `${i18n.t('menu.products')} | ${titleTemplate}`,
          isDebtorServiceable: true
        }
      },
      {
        path: 'products/railrod/:articleOrderId/',
        name: 'railrod-edit',
        component: RailRodPage,
        props: true,
        meta: {
          currentMenu: {
            text: i18n.t('menu.products'),
            name: 'products'
          },
          pageTitle: `${i18n.t('menu.products')} | ${titleTemplate}`
        }
      },
      {
        path: 'products/railrodparts',
        name: 'railrodparts',
        component: RailRodPartsPage,
        meta: {
          currentMenu: {
            text: i18n.t('menu.products'),
            name: 'products'
          },
          pageTitle: `${i18n.t('menu.products')} | ${titleTemplate}`,
          isDebtorServiceable: true
        }
      },
      {
        path: 'products/railrodparts/:articleOrderId/',
        name: 'railrodparts-edit',
        component: RailRodPartsPage,
        props: true,
        meta: {
          currentMenu: {
            text: i18n.t('menu.products'),
            name: 'products'
          },
          pageTitle: `${i18n.t('menu.products')} | ${titleTemplate}`
        }
      },
      {
        path: 'offer',
        name: 'offer',
        component: OffersPage,
        meta: {
          currentMenu: {
            text: i18n.t('menu.offer'),
            name: 'offer'
          },
          pageTitle: `${i18n.t('menu.offer')} | ${titleTemplate}`
        }
      },
      {
        path: 'order',
        name: 'order',
        component: OrdersPage,
        meta: {
          currentMenu: {
            text: i18n.t('menu.order'),
            name: 'order'
          },
          pageTitle: `${i18n.t('menu.order')} | ${titleTemplate}`
        }
      },
      {
        path: 'track',
        name: 'track',
        component: OrdersTrackingPage,
        meta: {
          currentMenu: {
            text: i18n.t('menu.track'),
            name: 'track'
          },
          pageTitle: `${i18n.t('menu.track')} | ${titleTemplate}`
        }
      },
      {
        path: 'sampleservice',
        name: 'sampleservice',
        component: SampleServicePage,
        props: {
          logo: false
        },
        meta: {
          currentMenu: {
            text: i18n.t('menu.sampleservice'),
            name: 'sampleservice'
          },
          pageTitle: `${i18n.t('menu.sampleservice')} | ${titleTemplate}`
        }
      },
      {
        path: 'customer-fabrics',
        name: 'customer-fabrics',
        component: CustomerFabricsPage,
        meta: {
          currentMenu: {
            text: i18n.t('menu.customer-fabrics'),
            name: 'customer-fabrics'
          },
          pageTitle: `${i18n.t('menu.customer-fabrics')} | ${titleTemplate}`
        }
      },
      {
        path: 'customer-fabrics/add',
        name: 'add-customer-fabric',
        component: AddCustomerFabricPage,
        meta: {
          currentMenu: {
            text: i18n.t('menu.customer-fabrics'),
            name: 'customer-fabrics'
          },
          pageTitle: `Add ${i18n.t('menu.customer-fabrics')} | ${titleTemplate}`
        }
      },
      {
        path: 'customer-fabrics/:fabricUUID/',
        name: 'edit-customer-fabric',
        component: AddCustomerFabricPage,
        props: true,
        meta: {
          currentMenu: {
            text: i18n.t('menu.customer-fabrics'),
            name: 'customer-fabrics'
          },
          pageTitle: `Edit ${i18n.t('menu.customer-fabrics')} | ${titleTemplate}`
        }
      },
      {
        path: 'settings/',
        name: 'settings',
        component: SettingsPage,
        meta: {
          currentMenu: {
            text: i18n.t('menu.settings'),
            name: 'settings'
          },
          pageTitle: `${i18n.t('menu.settings')} | ${titleTemplate}`
        }
      },
      {
        path: 'service',
        name: 'service',
        component: AccountPage,
        meta: {
          currentMenu: {
            text: i18n.t('menu.service'),
            name: 'service'
          },
          pageTitle: `${i18n.t('menu.service')} | ${titleTemplate}`
        }
      },
      {
        path: 'curtain-breeze',
        name: 'curtain-breeze',
        component: CurtainBreezePage,
        meta: {
          currentMenu: {
            text: i18n.t('menu.curtain-breeze'),
            name: 'curtain-breeze'
          },
          pageTitle: `${i18n.t('menu.curtain-breeze')} | ${titleTemplate}`
        }
      },
      {
        path: 'help',
        name: 'help',
        component: SupportPage,
        meta: {
          currentMenu: {
            text: i18n.t('menu.help'),
            name: 'help'
          },
          pageTitle: `${i18n.t('menu.help')} | ${titleTemplate}`
        }
      },
      {
        path: 'release-notes',
        name: 'release-notes',
        component: ReleaseNotesPage,
        meta: {
          currentMenu: {
            text: i18n.t('menu.release-notes'),
            name: 'release-notes'
          },
          pageTitle: `${i18n.t('menu.release-notes')} | ${titleTemplate}`
        }
      }
    ]
  },
  { path: '*', redirect: '/login' }
]

export default routes
