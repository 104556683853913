import axios from 'axios'
import store from '../store'
import router from '../router/router'
import { setupCache } from 'axios-cache-interceptor'

const ccApiUrl = process.env.VUE_APP_CURTAIN_CONFIG_API_HOST
const debug = process.env.NODE_ENV !== 'production'

const apiClient = axios.create({
  baseURL: ccApiUrl,
  headers: {
    'accept-language': process.env.VUE_APP_I18N_LOCALE || 'en-au'
  }
})
export const cachedAxios = axios.defaults.cache
  ? axios
  : setupCache(axios, { cacheTakeover: false })

apiClient.interceptors.response.use(
  function (response) {
    if (debug) {
      console.log('Response:', response)
    }
    return response
  },
  async function (error) {
    if (error.response) {
      const { status } = error.response
      // handle expired token error - except for token urls
      // retrying token verification and refresh can cause a loop if tokens are invalid
      if (status === 401 && error.config.url.indexOf('/token/') === -1) {
        const next = router.currentRoute.fullPath
        const rejectFunc = () => {
          router.push({ name: 'login', query: { next } }).catch(() => {})
          return Promise.reject(error.response)
        }
        const retryFunc = (config) => {
          config.headers['authorization'] = apiClient.defaults.headers['authorization']
          return apiClient.request(config)
        }
        // refresh token
        return await store.dispatch('user/refreshToken').then((status) => {
          if (status === 2) {
            return retryFunc(error.config)
          } else {
            return rejectFunc()
          }
        }, rejectFunc)
      }
    }
    return Promise.reject(error.response)
  }
)

apiClient.interceptors.request.use(function (config) {
  if (debug) {
    console.log('Request:', config)
  }
  config.headers['accept-language'] = store.getters['user/getSystemLanguage'] || 'en-au'
  return config
})

export default apiClient
