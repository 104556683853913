<template>
  <v-app>
    <v-container class="publicPage">
      <v-row>
        <v-col cols="5" sm="3" md="2">
          <router-link to="home">
            <v-img
              min-width="100px"
              class="mr-12 mb-7 mt-2"
              alt="Vadain"
              src="~@/assets/Vadain_logo_fc.svg"
              transition="scale-transition"
            />
          </router-link>
        </v-col>
        <v-col cols="7" sm="9" md="10" />
      </v-row>

      <v-row class="mainRow" no-gutters align="center">
        <v-col cols="12" md="7">
          <v-img src="~@/assets/Mania01.jpg" position="8% 80%" :height="imageHeight" />
        </v-col>
        <router-view />
      </v-row>
    </v-container>
  </v-app>
</template>

<script setup>
import { computed } from 'vue'
import { useVuetify } from '@/composables/useVuetify'

const vuetify = useVuetify()

const imageHeight = computed(() => {
  if (vuetify.breakpoint.sm || vuetify.breakpoint.xs) {
    return '400px'
  }
  return 'calc(80vh)'
})
</script>

<style lang="scss">
.publicPage {
  background-color: #fff;

  &.container {
    max-width: 1500px;
  }

  .form-container {
    max-width: 460px;
  }

  .mainRow {
    background-color: #f6f5f4;
  }

  h4.page-subtitle {
    font-size: 17px;
    font-weight: 300;
  }
}

.pa-22 {
  padding: 88px !important;
}
</style>
