import railRodPartsEndpoints from '@/api/railrodparts'
import { tryExceptAwait } from '@/utils'

import filters from './filters'

const getDefaultState = () => {
  return {
    // the list of rail rod parts
    railRodParts: [],
    count: null
  }
}

// initial state
const state = getDefaultState()

// getters
const getters = {
  getRailRodParts(state) {
    return state.railRodParts
  },
  getCount(state) {
    return state.count
  }
}

// mutations
const mutations = {
  setRailRodParts(state, railRodParts) {
    state.railRodParts = railRodParts
  },
  setCount(state, count) {
    state.count = count
  },
  resetState() {
    Object.assign(state, getDefaultState())
  }
}

// actions
const actions = {
  async loadRailRodParts({ commit }, { queryParams }) {
    const response = await tryExceptAwait(railRodPartsEndpoints.getRailRodParts, [
      queryParams
    ])
    if (response.data) {
      commit('setRailRodParts', response.data.results)
      commit('setCount', response.data.count)
    }
  },
  resetState({ commit }) {
    commit('resetState')
  }
}

export default {
  namespaced: true,
  modules: {
    filters
  },
  state,
  getters,
  mutations,
  actions
}
