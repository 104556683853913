import { createArticleOrder, updateArticleOrder } from '@/utils'

import { PULL_ROD, CREATED, OK, BAD_REQUEST, FORBIDDEN } from '@/constants'

const getDefaultState = () => {
  return {
    // id of the pull rod article order being edited
    pullRodOrderNumber: undefined,
    // boolean, should the pull/rod step be added?
    withPullRod: false,
    // the amount of curtain and/or pull rod articles that
    // should be created on completing the flow
    quantity: 1,
    // the template containing information about the possible
    // options for a certain pull rod type
    pullRodTemplate: {},
    // the flow of the pull rod that should be mapped from
    // order data and loaded into the PullRodFlow component
    pullRodFlow: {},
    // parameters which can be used to calculate a pull rod
    // price or save order data
    pullRodParameters: {},
    // the price object returned by the pull rod price endpoints
    pullRodPriceData: {}
  }
}

// initial state
const state = getDefaultState()

// getters
const getters = {
  getWithPullRod(state) {
    return state.withPullRod
  },
  getQuantity(state) {
    return state.quantity
  },
  getPullRodTemplate(state) {
    return state.pullRodTemplate
  },
  getPullRodFlow(state) {
    return state.pullRodFlow
  },
  getPullRodParameters(state) {
    return state.pullRodParameters
  },
  getPullRodPriceData(state) {
    return state.pullRodPriceData
  },
  getPullRodOrderNumber(state) {
    return state.pullRodOrderNumber
  }
}

// mutations
const mutations = {
  setWithPullRod(state, withPullRod) {
    state.withPullRod = withPullRod
  },
  setQuantity(state, quantity) {
    state.quantity = quantity
  },
  setPullRodTemplate(state, pullRodTemplate) {
    state.pullRodTemplate = pullRodTemplate
  },
  setPullRodFlow(state, pullRodFlow) {
    state.pullRodFlow = pullRodFlow
  },
  setPullRodParameters(state, pullRodParameters) {
    state.pullRodParameters = pullRodParameters
  },
  addPullRodParameter(state, pullRodParameter) {
    state.pullRodParameters = {
      ...state.pullRodParameters,
      ...pullRodParameter
    }
  },
  setPullRodPriceData(state, pullRodPriceData) {
    state.pullRodPriceData = pullRodPriceData
  },
  setPullRodOrderNumber(state, orderNumber) {
    state.pullRodOrderNumber = orderNumber
  }
}

// actions
const actions = {
  setWithPullRod({ commit }, withPullRod) {
    commit('setWithPullRod', withPullRod)
  },
  setQuantity({ commit }, quantity) {
    commit('setQuantity', quantity)
  },
  setPullRodTemplate({ commit }, pullRodTemplate) {
    commit('setPullRodTemplate', pullRodTemplate)
  },
  setPullRodFlow({ commit }, pullRodFlow) {
    commit('setPullRodFlow', pullRodFlow)
  },
  addPullRodParameter({ commit }, pullRodParameter) {
    commit('addPullRodParameter', pullRodParameter)
  },
  setPullRodParameters({ commit }, pullRodParameters) {
    commit('setPullRodParameters', pullRodParameters)
  },
  setPullRodPriceData({ commit }, pullRodPriceData) {
    commit('setPullRodPriceData', pullRodPriceData)
  },
  resetPullRod({ commit, rootGetters }) {
    const curtainOrderNumber = rootGetters['order/getCurtainOrderNumber']
    const room = rootGetters['order/getRoom']
    commit('setPullRodTemplate', {})
    commit('setPullRodFlow', {})
    commit(
      'setPullRodParameters',
      Object.assign(
        { room },
        curtainOrderNumber && { curtain_order_number: curtainOrderNumber }
      )
    )
    commit('setPullRodPriceData', {})
    commit('setQuantity', 1)
    commit('setWithPullRod', false)
  },
  resetState({ commit }) {
    commit('resetState')
  },
  async createOrUpdatePullRodOrder({ state, commit, dispatch, rootGetters }) {
    const orderId =
      rootGetters['order/getOrderId'] ||
      (await dispatch('order/getOrCreateDraftOrder', null, { root: true }))
    const { data, status } = state.pullRodOrderNumber
      ? // update existing pull rod order
        await updateArticleOrder(
          orderId,
          state.pullRodOrderNumber,
          PULL_ROD,
          state.pullRodParameters
        )
      : // create new pull rod order
        await createArticleOrder(orderId, PULL_ROD, state.pullRodParameters)

    if (status === OK || status === CREATED) {
      commit('order/setErrorMessage', {}, { root: true })
      commit('setPullRodOrderNumber', data.order_number)
    } else if (status >= BAD_REQUEST && status !== FORBIDDEN) {
      commit('order/setErrorMessage', data, { root: true })
      console.error('There was an error while creating or updating a pull rod order')
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
