<template>
  <v-container class="pa-0">
    <div class="mt-10 mx-5 mx-md-15">
      <div class="d-flex flex-wrap justify-md-space-between align-center">
        <h1 class="pt-2 pb-4 mr-2">Release notes</h1>
        <v-text-field
          v-model="searchValue"
          outlined
          dense
          clearable
          hide-details
          type="text"
          class="notesSearch mb-3"
          :label="t('pages.releaseNotes.searchLabel')"
          append-icon="mdi-magnify"
          @click:clear="clearSearch"
        >
          <template #append>
            <v-divider vertical class="mr-2" />
            <v-icon class="search-icon"> mdi-magnify </v-icon>
          </template>
        </v-text-field>
      </div>
      <v-card class="outlinedCard" tile outlined>
        <v-data-table
          :items="filteredNotes"
          :headers="notesHeaders"
          :footer-props="footerProps"
          :loading="isLoading"
          :items-per-page="7"
          disable-sort
          class="notesTable"
        >
          <template #item.title="{ item }">
            <vue-markdown :source="item.title" />
          </template>
        </v-data-table>
      </v-card>
    </div>
  </v-container>
</template>

<script setup>
import { computed, inject, ref, onMounted } from 'vue'
import { useI18n } from 'vue-i18n-composable'
import VueMarkdown from '@adapttive/vue-markdown'
import releaseNotesApi from '@/api/releasenotes'
import { timeStampToDate, tryExceptAwait } from '@/utils'

// DATA
const { t } = useI18n()
const notes = ref([])
const notesHeaders = ref([
  {
    text: t('components.datatables.releaseNotes.headers.version'),
    value: 'version'
  },
  { text: t('components.datatables.releaseNotes.headers.title'), value: 'title' },
  { text: t('components.datatables.releaseNotes.headers.date'), value: 'date' }
])
const footerProps = ref({
  pageText: t('components.datatables.releaseNotes.footer.pageText'),
  'items-per-page-options': [7, 14, 21, 28, 35]
})
const searchValue = ref('')
const wait = inject('wait')

// COMPUTED
const filteredNotes = computed(() => {
  return notes.value.filter((item) => {
    return item.title.toLowerCase().includes(searchValue.value.toLowerCase())
  })
})
const isLoading = computed(() => {
  return wait.is('loadingReleaseNotes')
})

// METHODS
const clearSearch = () => {
  searchValue.value = ''
}

// MOUNTED
onMounted(async () => {
  wait.start('loadingReleaseNotes')
  const { data, status } = await tryExceptAwait(releaseNotesApi.getReleaseNotes)
  if (status === 200) {
    notes.value = data.map((note) => ({
      version: note.name,
      title: note.message,
      date: timeStampToDate(note.commit.committed_date, 'en')
    }))
  }
  wait.end('loadingReleaseNotes')
})
</script>

<style lang="scss">
.outlinedCard {
  background-color: #ffffff;
  padding: 30px;
}
.notesTable {
  font-family: 'Source Sans Pro', sans-serif;
  tr:not(.v-data-table__empty-wrapper) td:last-child,
  tr th:last-child {
    text-align: right !important;
  }
  thead {
    background-color: #f5f6fa;
  }
  &.v-data-table.theme--light {
    > .v-data-table__wrapper {
      table {
        > tbody {
          > tr {
            th {
              text-transform: uppercase;
              span {
                color: #a3a6b4;
              }
            }
            > td {
              padding: 10px;
              height: 69px;
              color: #42415c;
              font-weight: 600;
              &:last-child {
                font-weight: 400;
                color: #808495;
              }
            }
          }
        }
      }
    }
    .v-data-footer {
      color: #43425d;
      font-size: 13px;
      padding-top: 30px;
      &__select {
        margin-right: auto;
        .v-select {
          margin-left: 13px;
          &__selection {
            color: #43425d;
            font-size: 13px;
          }
          .v-icon {
            color: #43425d;
          }
        }
        .v-input__slot {
          &:before {
            border: 0;
          }
        }
      }
      .v-btn--icon.v-size--default {
        height: 25px;
        width: 25px;
      }
    }
  }
  .v-data-table {
    &__mobile-row__header {
      margin-right: 10px;
    }
  }
}
.notesSearch {
  &.v-input.theme--light {
    max-width: 372px;
    background: white;
    font-family: 'Source Sans Pro', sans-serif;
    border-radius: 4px;
    fieldset {
      border-color: #eaebee;
    }
    .v-label,
    input {
      color: #4d4f5c;
      font-size: 14px;
    }
    .v-icon {
      color: #262626;
    }
  }
}
</style>
