import packageJson from '@/../package.json'
import apiClient from '@/api/index'

const GITLAB_API_ENDPOINT = 'api/latest-tag/'
const CHECK_INTERVAL = 3600000 // 1 hour in milliseconds

export async function checkVersion() {
  const lastCheck = localStorage.getItem('lastVersionCheck')
  const now = new Date().getTime()
  if (!lastCheck || now - lastCheck > CHECK_INTERVAL || lastCheck === 'FAIL') {
    try {
      const response = await apiClient.get(GITLAB_API_ENDPOINT)
      const latestTag = response.data

      if (latestTag) {
        if (latestTag !== packageJson.version) {
          localStorage.setItem('lastVersionCheck', now)
          location.reload(true)
        } else {
          localStorage.setItem('lastVersionCheck', now)
        }
      } else {
        console.error('Gitlab response error: Latest tag is undefined or null')
        localStorage.setItem('lastVersionCheck', 'FAIL')
      }
    } catch (error) {
      console.error('Error fetching latest tag:', error)
    }
  }
}
